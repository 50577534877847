export default {
  SowingsPlanning: {
    visited_page: 'visitedPage_planningSowings',
    period_filter: 'usedFeature_filteredByPeriod',
    field_filter: 'usedFeature_filteredByField',
    planning_filter: 'usedFeature_filteredByPlanning',
    crop_filter: 'usedFeature_filteredByCrop',
    add_sowing: 'clickedButton_addSowing',
    see_field: 'clickedButton_seeField',
    add_new_sowing_date: 'clickedButton_addNewSowingDate',
    delete_sowing: 'clickedButton_deleteSowing',
    see_plannings: 'clickedButton_seePlannings',
    edit_sowing: 'clickedButton_editSowing',
    execute_in_season: 'clickedButton_executeInSeason',
    copy: 'clickedButton_copy',
    finish_add_sowing: 'clickedButton_finishAddSowing',
    finish_add_new_sowing_date: 'clickedButton_finishAddNewSowingDate',
    complementary_data: 'clickedButton_complementaryData',
    execute_sowing_season: 'clickedButton_finishExecuteSowingInSeason',
    finish_copy: 'clickedButton_finishCopy',
  },
}
