export default {
  SowingView: {
    saved_changes: 'Saved changes',
    header: {
      add_spraying: 'Add spraying',
      edit_sowing: 'Edit sowing',
    },
    home: {
      next_sprayings: 'Next sprayings',
      filter_by: 'Filter by',
      without_spraying_text:
        'Go to planning and execute a program, or register a spraying directly here',
      without_spraying_title: 'There are no sprayings registered',
    },
    filters: {
      all_sprayings: 'All sprayings',
      late: 'Late',
      done: 'Done',
    },
    spraying_list: {
      ideal_date: 'Ideal date',
      type: 'Type',
      price_per_area: '$/ac',
      product_dose: 'Product • Dose',
    },
    spraying_list_item: {
      registered_on: 'Registered on',
      days_late: 'late days',
      edit: 'Edit',
      register: 'Register',
      spraying_effectiveness: 'Spraying effectiveness',
      delete: 'Delete',
      done: 'Done',
      late: 'Late',
      disease: 'Fungicide',
      edit_registry: 'Edit registry',
    },
    sowing_summary: {
      summary: 'Summary',
      status: 'Status',
      sown_area: 'Sown area',
      sown_at: 'Sown at',
      estimated_harvest: 'Estimated harvest',
      total_costs: 'Total costs of inputs',
      bags: 'bags',
    },
    sowing_growth_stage: {
      growth_stage: 'Growth stage',
      edit: 'Edit',
      full: 'Full',
      stage: 'Stage',
      vegetative: 'Vegetative',
      reproductiveInitial: 'Reproductive inicial',
      reproductiveFinal: 'Reproductive final',
      maturation: 'Maturation',
    },
    sowing_field_infos: {
      about: 'About the field',
      see_field: 'See field',
      other_sowings: 'Other sowings',
    },
    add_spraying_modal: {
      add_spraying: 'Add spraying',
      edit_spraying: 'Save',
      add_title: 'Register spraying',
      edit_title: 'Edit spraying',
      diseases: 'Diseases',
      control_type: 'Control type',
      spraying: 'Spraying',
      pesticide: 'Pesticide',
      select_pesticide: 'Select a product',
      dose: 'Dose',
      insert_dose: 'Enter a dose',
      price: 'Price',
      insert_price: 'Enter a price',
      new_pesticide: 'New pesticide',
      product__options: 'Product options',
      recommended_doses: 'Recommended doses',
      delete: 'Delete',
      registered_spraying: 'Registered spraying',
    },
    dose_tooltip: {
      choose_product: 'Choose a product',
    },
    main_diseases_picker: {
      main_diseases: 'Main diseases',
      priority: 'Priority',
      secondary: 'Secondary',
      mandatory_field: 'Choose at least the main disease',
    },
    product_mixture_information: {
      products: 'Products',
      description:
        'Potential efficacy is a simulation of how effective the product may be against a given disease. When adding products to your program, this efficacymay change, as we take into account several variables to show the real efficacy of the product.',
      potential_efficacy: 'Potential efficacy',
      active_ingredients: 'Active ingredients',
      select_products: 'Select products',
      empty_state:
        'Select a product to see its potential efficacy and active ingredients',
      single: 'Single',
      mixture: 'Mixture',
    },
    products_options: {
      select_products: 'Select the products',
      description:
        'When selecting the first product, we will suggest a reinforcement that can increase the efficacy of your application',
      empty_state: 'There are no suggested products. Select a disease.',
    },
    spraying_efficacy_modal: {
      title: 'Spraying efficacy',
      efficacies: 'Efficacies',
      products: 'Products',
    },
    register_spraying_modal: {
      register: 'Register',
      register_spraying: 'Register spraying',
      spraying_date: 'Spraying date',
      select_date: 'Select date',
      registered_spraying: 'Registered spraying',
    },
    growth_stage_modal: {
      save: 'Save',
      edit_growth_stage: 'Edit growth stage',
      growth_stage: 'Growth stage',
    },
    delete_modal: {
      text: "This action can't be undone",
      title: 'Are you sure you want to delete this spraying?',
    },
  },
}
