const Module = () =>
  import(
    /* webpackChunkName: "sowing-view-module" */ '@/modules/sowingView/Module.vue'
  )
const SowingViewHome = () =>
  import(
    /* webpackChunkName: "sowing-view-module" */ '@/modules/sowingView/views/SowingViewHome.vue'
  )

const routes = [
  {
    path: '/sowing',
    component: Module,
    children: [
      {
        path: '',
        component: SowingViewHome,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
