export default {
  SowingView: {
    saved_changes: 'Alterações salvas',
    header: {
      add_spraying: 'Cadastrar aplicação',
      edit_sowing: 'Editar semeadura',
    },
    home: {
      next_sprayings: 'Próximas aplicações',
      filter_by: 'Filtre por',
      without_spraying_text:
        'Vá para planejamento e execute um programa, ou cadastre uma aplicação diretamente por aqui',
      without_spraying_title: 'Não há aplicações cadastradas',
    },
    filters: {
      all_sprayings: 'Todas as aplicações',
      late: 'Atrasadas',
      done: 'Realizadas',
    },
    spraying_list: {
      ideal_date: 'Data ideal',
      type: 'Tipo',
      price_per_area: 'R$/ha',
      product_dose: 'Produto • Dose',
    },
    spraying_list_item: {
      registered_on: 'Registrado em',
      days_late: 'dias atrasados',
      edit: 'Editar',
      register: 'Registrar',
      spraying_effectiveness: 'Eficácia da aplicação',
      delete: 'Excluir',
      done: 'Realizado',
      late: 'Atrasado',
      disease: 'Fungicida',
      edit_registry: 'Editar registro',
    },
    sowing_summary: {
      summary: 'Resumo',
      status: 'Status',
      sown_area: 'Área semeada',
      sown_at: 'Semeado em',
      estimated_harvest: 'Colheita estimada',
      total_costs: 'Custo total em insumos',
      bags: 'sacas',
    },
    sowing_growth_stage: {
      growth_stage: 'Estádio fenológico',
      edit: 'Editar',
      full: 'Completo',
      stage: 'Estado',
      vegetative: 'Vegetativo',
      reproductiveInitial: 'Reprodutiva inicial',
      reproductiveFinal: 'Reprodutiva final',
      maturation: 'Maturação',
    },
    sowing_field_infos: {
      about: 'Sobre o talhão',
      see_field: 'Ver talhão',
      other_sowings: 'Outras semeaduras',
    },
    add_spraying_modal: {
      add_spraying: 'Adicionar aplicação',
      edit_spraying: 'Salvar',
      add_title: 'Cadastrar aplicação',
      edit_title: 'Editar aplicação',
      diseases: 'Doenças',
      control_type: 'Tipo de controle',
      spraying: 'Aplicação',
      pesticide: 'Defensivo',
      select_pesticide: 'Selecione um produto',
      dose: 'Dose',
      insert_dose: 'Insira uma dose',
      price: 'Preço',
      insert_price: 'Insira um preço',
      new_pesticide: 'Novo defensivo',
      product__options: 'Opções de produtos',
      recommended_doses: 'Doses recomendadas',
      delete: 'Excluir',
      registered_spraying: 'Aplicação cadastrada',
    },
    dose_tooltip: {
      choose_product: 'Escolha um produto',
    },
    main_diseases_picker: {
      main_diseases: 'Doenças principais',
      priority: 'Prioritária',
      secondary: 'Secundária',
      mandatory_field: 'Selecione ao menos a doença prioritária',
    },
    product_mixture_information: {
      products: 'Produtos',
      description:
        'Eficácia potencial é uma simulação de quanto o produto pode ser eficaz contra determinada doença. Ao adicionar os produtos no seu programa, essa eficácia poderá mudar, pois levamos em conta diversas variáveis para mostrar a eficácia real do produto.',
      potential_efficacy: 'Eficácia potencial',
      active_ingredients: 'Ingredientes ativos',
      select_products: 'Selecionar produtos',
      empty_state:
        'Selecione um produto para ver sua eficácia potencial e os ingredientes ativos',
      single: 'Único',
      mixture: 'Mistura',
    },
    products_options: {
      select_products: 'Selecione os produtos',
      description:
        'Ao selecionar o primeiro produto, vamos sugerir um reforço que pode elevar a eficácia da sua aplicação',
      empty_state: 'Não há produtos sugeridos. Selecione uma doença.',
    },
    spraying_efficacy_modal: {
      title: 'Eficácia da aplicação',
      efficacies: 'Eficácias',
      products: 'Produtos',
    },
    register_spraying_modal: {
      register: 'Registrar',
      register_spraying: 'Registrar aplicação',
      spraying_date: 'Data da aplicação',
      select_date: 'Selecionar data',
      registered_spraying: 'Aplicação registrada',
    },
    growth_stage_modal: {
      save: 'Salvar',
      edit_growth_stage: 'Editar estádio fenológico',
      growth_stage: 'Estádio fenológico',
    },
    delete_modal: {
      text: 'Essa ação não poderá ser desfeita',
      title: 'Tem certeza que deseja excluir essa aplicação?',
    },
  },
}
