export default {
  SowingsPlanning: {
    phytosanitary_category: {
      disease: 'Fungicide',
      insect: 'Insects',
      nematode: 'Nematodes',
      weed: 'Herbicide',
    },
    search_for_field: 'Search for a field',
    search_for_crop: 'Search for a crop',
    filter_not_found_text: 'Try changing the filters',
    filter_not_found_title: 'No results found',
    date_picker_component: {
      estimated_sowing_date: 'Estimated sowing date',
      select_date: 'Select date',
      estimated_sowing_hint: 'Period you intend to sow',
    },
    home: {
      plan_sowings: 'Plan sowings',
      filter_by: 'Filter by',
      crop: 'Crop',
      field: 'Field',
      plannings: 'Plannings',
      period: 'Period',
      without_field_text: 'Create a field to start planning your sowings',
      without_field_title: "You still don't have a field",
      without_sowing_text: 'Add a sowing and start managing your plannings',
      without_sowing_title: "You still don't have sowings in this field",
      add_sowing: 'Add sowing',
      clean_filters: 'Clean filters',
      filters: {
        next_6_months: 'Next 6 months',
        next_12_months: 'Next 12 months',
        next_24_months: 'Next 24 months',
        previous_sowings: 'Past sowings',
        custom_date: 'Custom date',
      },
    },
    field_sowing_planning: {
      see_field: 'See field',
      sowings: 'sowings',
      sowing_not_found_text: 'Add a sowing and start carrying out your plans',
      sowing_not_found_title: "You still don't have sowings in this field",
    },
    variety_list_item: {
      none: 'None',
      crop: {
        wheat: 'Wheat',
        corn: 'Corn',
        soybean: 'Soybean',
      },
      programs_made: 'Plannings made',
      estimated_sowing_date: 'Estimated sowing date',
      executed: 'Executed',
      see_plannings: 'See plannings',
      delete: 'Delete',
      sowing_actions: {
        edit: 'Edit',
        execute_in_season: 'Execute in season',
        copy_to: 'Copy to',
      },
      variety_actions: {
        new_sowing_date: 'New sowing date',
      },
    },
    add_sowing_modal: {
      add_sowing: 'Add sowing',
      add: 'Add',
      edit_sowing: 'Edit sowing',
      edit: 'Edit',
      successful_request_title: 'Sowing added',
    },
    sowing_form: {
      field: 'Field',
      select_one_field: 'Select one field',
      crop: 'Crop',
      select_one_crop: 'Select one crop',
      genetic: 'Genetic',
      select_one_genetic: 'Select one genetics',
      insert_genetic: "Enter genetics' name",
      company: 'Company',
      insert_company: "Enter company's name",
      genetic_infos: 'If you need more information about genetics, ',
      click_here: 'click here',
      request: 'Request',
      genetic_not_found: "Didn't find a genetic? Ask our service team",
      days: 'Days',
      estimated_cycle: 'Estimated cycle',
      enter_estimated_cycle: 'Enter the estimated cycle',
    },
    add_date_sowing_modal: {
      new_sowing_date: 'New sowing date',
      add: 'Add',
      successful_request_title: 'Date of sowing created',
    },
    custom_date_modal: {
      custom_period: 'Customize period',
      insert_date: 'Insert date',
      start: 'Start',
      end: 'End',
    },
    request_genetic_modal: {
      request: 'Request',
      request_genetic: 'Request genetic',
      genetic: 'Genetic',
      insert_genetic_name: "Insert genetic's name",
      company: 'Company',
      insert_company_name: "Insert company's name",
      successful_request_title: 'Requested genetic',
      successful_request_text:
        'We will analyze your request internally and will bring you more information soon.',
    },
    delete_variety_modal: {
      title: 'Are you sure you wish to delete all sowings e programs?',
      text: 'All planning data and programs inside this sowing will be lost and this action can not be reverted.',
      created_sowings: 'Created sowings',
      created_programs: 'Created programs',
      sowings_deleted: 'Sowings deleted',
    },
    delete_sowing_modal: {
      title: 'Are you sure you wish to delete this sowing date?',
      text: 'If there are any programs associated, they will also be deleted.',
      sowing_date: 'Sowing date',
      created_programs: 'Created programs',
      sowing_date_deleted: 'Sowing date deleted',
    },
    copy_sowing_modal: {
      copy_sowing: 'Copy sowing',
      copy: 'Create copy',
      created_plannings: 'Created plannings',
      select_field: 'Select field',
      select_field_description:
        'Select the fields you want to make a copy of this sowing',
      successful_request_title: 'Sowing copied to the selected fields',
    },
    execute_season: {
      title: 'Execute sowing in season',
      warning:
        'Attention, you will only be performing the sowing. If you have made plannings and want to execute them as well, you need to access the desired planning programs and execute them individually.',
      execute: 'Execute in season',
      sown_area: 'Sown area',
      insert_area: 'Insert area',
      sowing_date: 'Sowing date',
      emergency_sowing_date: 'Timing of emergency',
      complementary_data: 'Complementary data',
      seed_class: 'Seed class',
      select: 'Select',
      germinative_power: 'Germinative power',
      type: 'Type',
      seed_vigor: 'Seed vigor',
      seed_system: 'Seed system',
      line_spacing: 'Line spacing',
      population_line: 'Population on line',
      population_line_suffix: 'Plants/linear m',
      sown_quantity: 'Sown quantity',
      operating_speed: 'Operating speed',
      origin: 'Origin:',
      bought: 'Bought',
      owned: 'Owned',
      seed_treatment: 'Carried out seed treatment?',
      yes: 'Yes',
      no: 'No',
      inoculant: 'Used inoculant?',
      harvested: 'Have you harvested?',
      harvested_quantity: 'How much was harvested?',
      successful_request_title: 'Sowing executed in season',
      successful_request_text:
        'You can check further details of this sowing in My Sowings',
      saved_changes: 'Saved changes',
    },
    exceptions: {
      list_error: 'We were unable to list the sowing programs. Try again.',
      add_sowing: 'We were unable to register the sowing. Try again.',
      request_genetic: 'We were unable to make the request. Try again.',
      no_field_selected: 'No field selected',
      over_field_max_area: 'The value is over the field max area',
    },
  },
}
