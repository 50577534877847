export default {
  SowingsPlanning: {
    phytosanitary_category: {
      disease: 'Fungicida',
      insect: 'Insectos',
      nematode: 'Nematodos',
      weed: 'Herbicida',
    },
    search_for_field: 'Buscar un lote',
    search_for_crop: 'Buscar por un cultivo',
    filter_not_found_text: 'Prueba a cambiar los filtros',
    filter_not_found_title: 'No encontramos resultados',
    date_picker_component: {
      estimated_sowing_date: 'Fecha estimada de siembra',
      select_date: 'Seleccione la fecha',
      estimated_sowing_hint: 'Periodo que pretende sembrar',
    },
    home: {
      plan_sowings: 'Planificar siembras',
      filter_by: 'Filtrado por',
      crop: 'Cultivo',
      field: 'Lote',
      plannings: 'planificación',
      period: 'Período',
      without_field_text: 'Crea un lote para empezar a planificar tus siembras',
      without_field_title: 'Aún no tienes un lote',
      without_sowing_text: 'Añade una siembra y empieza a realizar tus planes',
      without_sowing_title: 'Aún no tienes siembras en esta parcela',
      add_sowing: 'Agregar siembra',
      clean_filters: 'Limpiar filters',
      filters: {
        next_6_months: 'Próximos 6 meses',
        next_12_months: 'Próximos 12 meses',
        next_24_months: 'Próximos 24 meses',
        previous_sowings: 'Siembras pasadas',
        custom_date: 'Fecha personalizada',
      },
    },
    field_sowing_planning: {
      see_field: 'Ver lote',
      sowings: 'siembras',
      sowing_not_found_text:
        'Añade una siembra y empieza a realizar tus planes',
      sowing_not_found_title: 'Aún no tienes siembras en este lote',
    },
    variety_list_item: {
      none: 'Ninguno',
      crop: {
        wheat: 'Trigo',
        corn: 'Maíz',
        soybean: 'Soja',
      },
      programs_made: 'Planes hechos',
      estimated_sowing_date: 'Fecha estimada de siembra',
      executed: 'Ejecutado',
      see_plannings: 'Ver planes',
      delete: 'Borrar',
      sowing_actions: {
        edit: 'Editar',
        execute_in_season: 'Realizar en la zafra',
        copy_to: 'Copiar a',
      },
      variety_actions: {
        new_sowing_date: 'Nueva fecha de siembra',
      },
    },
    add_sowing_modal: {
      add_sowing: 'Agregar siembra',
      add: 'Agregar',
      edit_sowing: 'Editar siembra',
      edit: 'Editar',
      successful_request_title: 'Siembra agregada',
    },
    sowing_form: {
      field: 'Lote',
      select_one_field: 'Seleccione un lote',
      crop: 'Cultivo',
      select_one_crop: 'Seleccione um cultivo',
      genetic: 'Genética',
      select_one_genetic: 'Seleccione una genética',
      insert_genetic: 'Ingrese el nombre de la genética',
      company: 'Empresa',
      insert_company: 'Ingrese el nombre de la empresa',
      genetic_infos: 'Si necesitas más información sobre genética, ',
      click_here: 'haz clic aquí',
      request: 'Pedido',
      genetic_not_found:
        '¿No encontraste un gen? Pregunta a nuestro equipo de servicio',
      days: 'Días',
      estimated_cycle: 'Ciclo estimado',
      enter_estimated_cycle: 'Ingrese el ciclo estimado',
    },
    add_date_sowing_modal: {
      new_sowing_date: 'Nueva fecha de siembra',
      add: 'Agregar',
      successful_request_title: 'Fecha de siembra creada',
    },
    custom_date_modal: {
      custom_period: 'Personalizar el período',
      insert_date: 'Ingresar una fecha',
      start: 'Inicio',
      end: 'Final',
    },
    request_genetic_modal: {
      request: 'Solicitar',
      request_genetic: 'Solicitar genética',
      genetic: 'Genética',
      insert_genetic_name: 'Ingrese el nombre de la genética',
      company: 'Empresa',
      insert_company_name: 'Ingrese el nombre de la empresa',
      successful_request_title: 'Genética solicitada',
      successful_request_text:
        'Analizaremos su solicitud internamente y pronto le brindaremos más información.',
      failed_request_text:
        'No pudimos realizar la solicitud. Inténtalo de nuevo.',
    },
    delete_variety_modal: {
      title:
        '¿Está seguro de que desea eliminar todas las siembras y programas?',
      text: 'Todos los datos y programas de planificación contenidos en esta siembra se perderán y esta acción no se podrá deshacer.',
      created_sowings: 'Siembras creadas',
      created_programs: 'Programas creados',
      sowings_deleted: 'Siembras excluidas',
    },
    delete_sowing_modal: {
      title: '¿Está seguro de que desea eliminar esta fecha de siembra?',
      text: 'Si tiene programas vinculados, también se eliminarán.',
      sowing_date: 'Fecha de siembra',
      created_programs: 'Programas creados',
      sowing_date_deleted: 'Fecha de siembra excluida',
    },
    copy_sowing_modal: {
      copy_sowing: 'Copia de siembra',
      copy: 'Hacer copia',
      created_plannings: 'Planes hechos',
      select_field: 'Seleccione el lote',
      select_field_description:
        'Seleccione los lotes que desea realizar una copia de esta siembra',
      successful_request_title: 'Siembra copiada a lotes seleccionados',
    },
    execute_season: {
      title: 'Realizar la siembra en zafra',
      warning:
        'Atención, sólo estarás realizando la siembra. Si ha hecho planificaciones y desea ejecutarlos también, debe acceder a los programas de planificación deseados y ejecutarlos individualmente.',
      execute: 'Realizar en zafra',
      sown_area: 'Área sembrada',
      insert_area: 'Ingrese a un área',
      sowing_date: 'Fecha de siembra',
      emergency_sowing_date: 'Fecha de emergencia',
      complementary_data: 'Datos complementarios',
      seed_class: 'Clase de semilla',
      select: 'Seleccionar',
      germinative_power: 'Poder germinativo',
      type: 'Escribelo',
      line_spacing: 'Espaçamento entre linhas',
      seed_vigor: 'Vigor de la semilla',
      seed_system: 'Sistema de siembra',
      population_line: 'Poblacíon en línea',
      population_line_suffix: 'Plantas/m lineales',
      sown_quantity: 'Cantidad sembrada',
      operating_speed: 'Velocidad de operación',
      origin: 'Origen:',
      bought: 'Comprado',
      owned: 'Proprio',
      seed_treatment: '¿Realizó usted tratamiento de semillas?',
      yes: 'Sí',
      no: 'No',
      inoculant: '¿Usaste inoculante?',
      harvested: '¿Ya has cosechado?',
      harvested_quantity: '¿Cuánto se cosechó?',
      successful_request_title: 'Siembra realizada en cosecha',
      successful_request_text:
        'Puedes ver más detalles sobre esta siembra en Mis Semillas',
      saved_changes: 'Cambios guardados',
    },
    exceptions: {
      list_error:
        'No pudimos enumerar los planificaciones de siembra. Inténtalo de nuevo.',
      add_sowing: 'No hemos podido registrar su siembra. Inténtalo de nuevo.',
      request_genetic: 'No pudimos realizar la solicitud. Inténtalo de nuevo.',
      no_field_selected: 'No hay lote seleccionados',
      over_field_max_area: 'El valor supera el área máxima del lote',
    },
  },
}
