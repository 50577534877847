export default {
  SowingsList: {
    visited_page: 'visitedPage_Sowings',
    add_sowing_in_season: 'clickedButton_AddSowingInSeason',
    filtered_by_period: 'usedFeature_FilteredByPeriod',
    filtered_by_field: 'usedFeature_FilteredByField',
    filtered_by_crop: 'usedFeature_FilteredByCrop',
    filtered_by_status: 'usedFeature_FilteredByStatus',
    see_field: 'clickedButton_SeeField',
    see_sowing: 'clickedButton_SeeSowing',
    edit_sowing: 'clickedButton_EditSowing',
    copy: 'clickedButton_Copy',
    delete_sowing: 'clickedButton_DeleteSowing',
    register_crop: 'clickedButton_RegisterCrop',
    finish_register_crop: 'clickedButton_FinishRegisterCrop',
  },
}
