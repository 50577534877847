export default {
  SowingsList: {
    header: {
      title: 'Minhas semeaduras',
      action: 'Executar semeadura',
    },
    home: {
      filter_by: 'Filtre por',
      crop: 'Cultura',
      field: 'Talhão',
      status: 'Status',
      period: 'Período',
      clean_filters: 'Limpar filtros',
      without_field_text:
        'Crie um talhão para começar a realizar os planejamentos de suas semeaduras',
      without_field_title: 'Você ainda não tem um talhão cadastrado',
      search_for_field: 'Pesquise por um talhão',
      search_for_crop: 'Pesquise por uma cultura',
    },
    field_sowings_list: {
      see_field: 'Ver talhão',
      sowings: 'semeaduras',
      sowing_not_found_text:
        'Clique no botão abaixo e execute uma semeadura, assim você poderá acompanhar sua evolução e aplicações',
      sowing_not_found_title: 'Execute uma semeadura neste talhão',
      filter_not_found_text: 'Tente mudar os filtros',
      filter_not_found_title: 'Não encontramos resultados',
    },
    variety_list_item: {
      crop: {
        wheat: 'Trigo',
        corn: 'Milho',
        soybean: 'Soja',
      },
      sown_at: 'Semeado em',
      harvested_at: 'Colhido em',
      to_sow_date: 'Semeará em',
      estimated_harvest_date: 'Colheita estimada',
      growth_stage: 'Estádio fenológico',
      productivity: 'Produtividade',
      register_harvest: 'Registrar colheita',
      sowing_actions: {
        edit: 'Editar',
        see_sowing: 'Ver semeadura',
        copy_to: 'Copiar para',
        delete: 'Excluir',
      },
    },
    status_chip: {
      harvested: 'Colheita feita',
      ready_to_harvest: 'Pronto para colheita',
      ongoing: 'Em andamento',
      scheduled: 'Programado',
    },
    delete_sowing_modal: {
      text: 'Todos os dados e programas contidos nessa semeadura serão perdidos e essa ação não poderá ser desfeita.',
      title: 'Tem certeza que deseja excluir semeadura?',
      sowing_deleted: 'Semeadura excluída com sucesso',
    },
    sowing_infos_card: {
      sowing_date: 'Data de semeadura',
      harvest_date: 'Data de colheita',
      productivity: 'Produtividade',
    },
    execute_sowing_modal: {
      edit_sowing: 'Editar semeadura',
      save: 'Salvar',
    },
    harvest_modal: {
      register: 'Registrar',
      title: 'Registrar colheita',
      bags_per_area: 'Sacas por hectare',
      insert_harvested_quantity: 'Insira a quantidade colhida',
      harvest_date: 'Data da colheita',
      insert_date: 'Insira uma data',
      did_desiccation: 'Fez dessecação?',
      yes: 'Sim',
      no: 'Não',
      lost_harvest: 'Perdi minha colheita',
    },
    exceptions: {
      list_error: 'Não conseguimos listar as semeaduras. Tente novamente.',
    },
  },
}
