const Module = () =>
  import(
    /* webpackChunkName: "sowings-list-module" */ '@/modules/sowingsList/Module.vue'
  )
const SowingsListHome = () =>
  import(
    /* webpackChunkName: "sowings-list-module" */ '@/modules/sowingsList/views/SowingsListHome.vue'
  )

const routes = [
  {
    path: '/sowings_list',
    component: Module,
    children: [
      {
        path: '',
        component: SowingsListHome,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
