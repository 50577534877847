export default {
  SowingView: {
    saved_changes: 'Cambios guardados',
    header: {
      add_spraying: 'Registrar aplicaciones',
      edit_sowing: 'Editar siembra',
    },
    home: {
      next_sprayings: 'Próximas aplicaciones',
      filter_by: 'Filtrado por',
      without_spraying_text:
        'Vaya a planificar y ejecutar un programa, o registre una aplicación directamente aquí',
      without_spraying_title: 'No hay aplicaciones registradas',
    },
    filters: {
      all_sprayings: 'Todas las aplicaciones',
      late: 'Tardías',
      done: 'Realizadas',
    },
    spraying_list: {
      ideal_date: 'Fecha ideal',
      type: 'Tipo',
      price_per_area: 'US$/ha',
      product_dose: 'Producto • Dosis',
    },
    spraying_list_item: {
      registered_on: 'Registrado el',
      days_late: 'días de retraso',
      edit: 'Editar',
      register: 'Registrar',
      spraying_effectiveness: 'Efectividad de la aplicación',
      delete: 'Borrar',
      done: 'Logrado',
      late: 'Tarde',
      disease: 'Fungicida',
      edit_registry: 'Editar registro',
    },
    sowing_summary: {
      summary: 'Resumen',
      status: 'Estado',
      sown_area: 'Área sembrada',
      sown_at: 'Sembrado en',
      estimated_harvest: 'Cosecha estimada',
      total_costs: 'Costo total en insumos',
      bags: 'sacas',
    },
    sowing_growth_stage: {
      growth_stage: 'Etapa de crecimiento',
      edit: 'Editar',
      full: 'Completo',
      stage: 'Etapa',
      vegetative: 'Vegetativa',
      reproductiveInitial: 'Inicial reproductiva',
      reproductiveFinal: 'Final reproductiva',
      maturation: 'Maduración',
    },
    sowing_field_infos: {
      about: 'Sobre el lote',
      see_field: 'Ver lote',
      other_sowings: 'Otra siembra',
    },
    add_spraying_modal: {
      add_spraying: 'Agregar aplicación',
      edit_spraying: 'Guardar',
      add_title: 'Registrar aplicación',
      edit_title: 'Editar aplicación',
      diseases: 'Enfermedades',
      control_type: 'Tipo de control',
      spraying: 'Aplicación',
      pesticide: 'Pesticidas',
      select_pesticide: 'Seleccione um producto',
      dose: 'Dosis',
      insert_dose: 'Introducir una dosis',
      price: 'Precio',
      insert_price: 'Introduce un precio',
      new_pesticide: 'Nuevo pesticida',
      product__options: 'Opciones de producto',
      recommended_doses: 'Dosis recomendadas',
      delete: 'Borrar',
      registered_spraying: 'Solicitud registrada',
    },
    dose_tooltip: {
      choose_product: 'Elige un producto',
    },
    main_diseases_picker: {
      main_diseases: 'Principales enfermedades',
      priority: 'Prioritaria',
      secondary: 'Secundaria',
      mandatory_field: 'Seleccione al menos la enfermedad principal',
    },
    product_mixture_information: {
      products: 'Productos',
      description:
        'La eficiencia potencial es una simulación de cuán efectivo puede ser el producto contra una enfermedad determinada. Al agregar productos a su programa, esta eficiencia puede cambiar, ya que tomamos en cuenta varias variables para mostrar la eficiencia real del producto.',
      potential_efficacy: 'Eficiencia potencial',
      active_ingredients: 'Ingredientes activos',
      select_products: 'Seleccionar productos',
      empty_state:
        'Seleccione un producto para ver su potencial eficiencia e ingredientes activos',
      single: 'Único',
      mixture: 'Mezcla',
    },
    products_options: {
      select_products: 'Seleccione los productos',
      description:
        'Al seleccionar el primer producto, le sugeriremos un refuerzo que puede aumentar la eficiencia de su aplicación',
      empty_state: 'No hay productos sugeridos. Seleccione una enfermedad.',
    },
    spraying_efficacy_modal: {
      title: 'Eficiencia de la aplicación',
      efficacies: 'Eficiencias',
      products: 'Productos',
    },
    register_spraying_modal: {
      register: 'Registrar',
      register_spraying: 'Registrar aplicación',
      spraying_date: 'Fecha de aplicación',
      select_date: 'Seleccionar fecha',
      registered_spraying: 'Aplicación registrada',
    },
    growth_stage_modal: {
      save: 'Salvar',
      edit_growth_stage: 'Editar etapa de crecimiento',
      growth_stage: 'Etapa de crecimiento',
    },
    delete_modal: {
      text: 'Esta acción no se puede deshacer',
      title: '¿Está seguro de que desea eliminar esta aplicación?',
    },
  },
}
