import {
  getSowingsPlanningSeeds,
  sowingsPlanningSeeds,
} from '@/modules/sowingsPlanning/mock/sowingsPlanningSeeds.js'
import { seedClasses, seederSystems } from '@/services/__mocks__/seederSeeds'
import { fields } from '@/modules/plot/mock/fieldSeeds'
import {
  executedSowings,
  getPlanningAndExecutedSowings,
  getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated,
  getPlanningSowingsWithoutDuplicated,
  planningSowings,
} from '@/services/__mocks__/sowingsSeeds'
import { farms } from '@/services/__mocks__/farmSeeds'
import { crops, varieties } from '@/pages/cultivares/mock/varietySeeds'
import { APIFailureWrapper } from '@/utils/mock'
import { mockFlag } from '@/utils/mock'
import { isDateBetween } from '@/utils/time'
import { allPrograms } from '@/modules/diseasesControl/mock/diseasesControlSeeds'

const sowingsPlanningRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/seed-class',
      result: () => {
        return APIFailureWrapper({
          content: seedClasses,
          errorMessage: 'Erro na listagem de classes de sementes',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/seeder-system',
      result: () => {
        return APIFailureWrapper({
          content: seederSystems,
          errorMessage: 'Erro na listagem de sistemas de semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/sowing-tasks',
      result: ({ queryParams }) => {
        let filteredFieldSowingsPlanning = getSowingsPlanningSeeds()
        if (queryParams.sowing_id) {
          const sowing = getPlanningAndExecutedSowings().find(
            (sowing) => sowing.id == queryParams.sowing_id
          )
          filteredFieldSowingsPlanning = {
            task: {
              estimated_start_date: sowing.estimated_date,
              estimated_completed_date: sowing.date,
              extra_info: {
                estimated_cycle: sowing.cycle,
                variety_id: sowing.variety_id,
                variety_name: sowing.variety_name,
                asset_owner_name: 'Mock Company',
                sown_area: sowing.area,
                date: sowing.date,
                emergence_date: sowing.emergence_date,
                seed_class_id: sowing.seed_class_id,
                germinative_power: sowing.germinative_power,
                seed_vigor: sowing.seed_vigor,
                seeder_system_id: sowing.seeder_system_id,
                line_spacing: sowing.line_spacing,
                population_lines: sowing.population_lines,
                sown_quantity: sowing.sown_quantity,
                operating_speed: sowing.operating_speed,
                origin: sowing.origin,
                seed_treatment: sowing.seed_treatment,
                inoculant: sowing.inoculant,
                harvested: sowing.harvested,
                harvest_quantity: sowing.harvest_quantity,
              },
            },
            crop_zone: {
              crop_id: sowing.crop_id,
              variety_id: sowing.variety_id,
              farm_id: sowing.farm_id,
              field_id: sowing.field_id,
            },
          }
        }
        if (queryParams.farm_id) {
          filteredFieldSowingsPlanning = filteredFieldSowingsPlanning.filter(
            (fieldSowing) => fieldSowing.farm_id == queryParams.farm_id
          )
        }
        if (queryParams.field_id) {
          filteredFieldSowingsPlanning = filteredFieldSowingsPlanning.filter(
            (fieldSowing) => queryParams.field_id.includes(fieldSowing.field_id)
          )
        }
        if (queryParams.date_period) {
          if (queryParams.date_period === '6m') {
            filteredFieldSowingsPlanning = filterByPeriod(
              filteredFieldSowingsPlanning,
              0,
              6
            )
          } else if (queryParams.date_period === '12m') {
            filteredFieldSowingsPlanning = filterByPeriod(
              filteredFieldSowingsPlanning,
              0,
              12
            )
          } else if (queryParams.date_period === '24m') {
            filteredFieldSowingsPlanning = filterByPeriod(
              filteredFieldSowingsPlanning,
              0,
              24
            )
          } else if (queryParams.date_period === 'previous_sowings') {
            filteredFieldSowingsPlanning = filterByPeriod(
              filteredFieldSowingsPlanning,
              120,
              0
            )
          } else {
            const dates = queryParams.date_period.split('_')
            const startDate = dates[0]
            const endDate = dates[1]
            filteredFieldSowingsPlanning = filterByPeriod(
              filteredFieldSowingsPlanning,
              0,
              0,
              startDate,
              endDate
            )
          }
        }
        if (queryParams.program_categories) {
          filteredFieldSowingsPlanning = filterByPrograms(
            filteredFieldSowingsPlanning,
            queryParams.program_categories
          )
        }
        if (queryParams.crop_id) {
          filteredFieldSowingsPlanning = filterByCrops(
            filteredFieldSowingsPlanning,
            queryParams.crop_id
          )
        }
        return APIFailureWrapper({
          content: filteredFieldSowingsPlanning,
          errorMessage: 'Erro na listagem de planejamentos de semeaduras',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/planning-sowings',
      result: ({ queryParams }) => {
        const filteredPlanningSowings =
          getPlanningSowingsWithoutDuplicated().filter((sowing) => {
            const dateInfos = sowing.estimated_date.split('-')
            const yearMonth = dateInfos[0] + '-' + dateInfos[1]
            return (
              sowing.field_id == queryParams.field_id &&
              sowing.variety_id == queryParams.variety_id &&
              yearMonth == queryParams.year_month
            )
          })
        return APIFailureWrapper({
          content: filteredPlanningSowings,
          errorMessage: 'Erro na listagem de semeaduras planejadas do mês',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/varieties',
      result: ({ queryParams }) => {
        let filteredVarieties = varieties
        if (queryParams.crop_id) {
          filteredVarieties = filteredVarieties.filter(
            (variety) => variety.crop.id == queryParams.crop_id
          )
        }
        return APIFailureWrapper({
          content: filteredVarieties,
          errorMessage: 'Erro na listagem de cultivares',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fields',
      result: ({ queryParams }) => {
        let filteredFields = fields
        if (queryParams.is_active === 'true') {
          filteredFields = filteredFields.filter(
            (field) => !!field.enabled && !field.deleted_at
          )
        }
        if (queryParams.farm_id) {
          filteredFields = filteredFields.reduce((acc, field) => {
            if (field.fazenda_id == queryParams.farm_id)
              acc.push({
                id: field.id,
                name: field.name,
                created_at: field.created_at,
              })
            return acc
          }, [])
        }
        return APIFailureWrapper({
          content: filteredFields,
          errorMessage: 'Erro na listagem de talhões',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/crops',
      result: () => {
        const formattedCrops = crops.map((crop) => {
          return {
            id: crop.id,
            name: crop.name,
            message_key: crop.message_key,
          }
        })
        return APIFailureWrapper({
          content: formattedCrops,
          errorMessage: 'Erro na listagem de cultivos',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/genetics',
      result: () => {
        const formattedGenetics = varieties.map((variety) => {
          return {
            id: variety.id,
            name: variety.name,
            crop_id: variety.crop.id,
          }
        })
        return APIFailureWrapper({
          content: formattedGenetics,
          errorMessage: 'Erro na listagem de genéticas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'patch',
      url: '/sowing-tasks/:sowing_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        editSowing({
          cycle: body.estimated_cycle,
          date: body.estimated_start_date,
          sowingId: params.sowing_id,
        })
        planningSowings.forEach((sowing) => {
          if (sowing.id == params.sowing_id) {
            sowing.estimated_date = body.estimated_start_date
          }
        })
        allPrograms.forEach((program) => {
          if (program.sowing_id == params.sowing_id) {
            if (program.date) program.date = body.estimated_start_date
            else program.estimated_sowing_date = body.estimated_start_date
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na edição da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/sowing-tasks/:sowing_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        editSowing({
          area: body.crop_zone.area,
          date: body.task.started_at,
          emergenceDate: body.task.extra_info.emergence_date,
          cycle: body.task.extra_info.cycle,
          statusName: 'executed',
          sowingId: params.sowing_id,
        })
        const executedSowing = {
          ...getPlanningAndExecutedSowings().find(
            (sowing) => sowing.id == params.sowing_id
          ),
        }
        executedSowing.area = Number(body.crop_zone.area)
        executedSowing.date = body.task.started_at
        executedSowing.emergence_date = body.task.extra_info.emergence_date
        executedSowing.cycle = body.task.extra_info.cycle
        executedSowing.sowing_status = 'executed'
        executedSowing.harvest_status = 'scheduled'
        executedSowings.push(executedSowing)
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na execução da semeadura na safra',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/sowing-tasks',
      result: ({ requestBody }) => {
        const body = JSON.parse(requestBody)
        body.crop_zone.field_id.forEach((fieldId) =>
          insertNewSowingToList({
            cropId: body.crop_zone.crop_id,
            cycle: body.task.extra_info.estimated_cycle,
            fieldId: fieldId,
            varietyId: body.crop_zone.variety_id,
            varName: body.task.extra_info.variety_name,
            sowingDate: body.task.estimated_start_date,
          })
        )
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/sowing-tasks/:sowing_id/copy',
      result: ({ params, requestBody }) => {
        let cropId, cycle, fieldId, sowingDate, varietyId
        const body = JSON.parse(requestBody)
        sowingsPlanningSeeds.forEach((task) => {
          task.varieties.forEach((variety) => {
            variety.sowings.forEach((sowing) => {
              if (sowing.id == params.sowing_id) {
                cropId = variety.crop_id
                cycle = sowing.cycle
                fieldId = task.field_id
                sowingDate = sowing.date
                varietyId = variety.variety_id
              }
            })
          })
        })
        if (body.field_id) {
          body.field_id.forEach((fieldId) =>
            insertNewSowingToList({
              cropId: cropId,
              cycle: cycle,
              fieldId: fieldId,
              varietyId: varietyId,
              sowingDate: sowingDate,
            })
          )
        } else {
          insertNewSowingToList({
            cropId: cropId,
            cycle: cycle,
            fieldId: fieldId,
            varietyId: varietyId,
            sowingDate: body.estimated_start_date,
          })
        }
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação/duplicação da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/request-genetic',
      result: () => {
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na requisição de genética',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/delete-variety/:variety_id',
      result: ({ params, queryParams }) => {
        sowingsPlanningSeeds.forEach((sowing) => {
          if (sowing.field_id == queryParams.field_id) {
            for (let index = sowing.varieties.length - 1; index >= 0; index--) {
              if (sowing.varieties[index].variety_id == params.variety_id) {
                sowing.varieties.splice(index, 1)
              }
            }
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção de cultivar/genética',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/delete-sowing/:sowing_id',
      result: ({ params }) => {
        sowingsPlanningSeeds.forEach((sowing) => {
          sowing.varieties.forEach((variety) => {
            for (let index = variety.sowings.length - 1; index >= 0; index--) {
              if (variety.sowings[index].id == params.sowing_id) {
                variety.sowings.splice(index, 1)
              }
            }
          })
        })

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção de planejamento de semeadura',
        })
      },
    },
    'on'
  ),
]

const filterByPeriod = (
  allFieldSowingsPlanning,
  monthsBehind = 0,
  monthsAhead = 0,
  startDate = '',
  endDate = ''
) => {
  return allFieldSowingsPlanning.map((obj) => ({
    ...obj,
    varieties: obj.varieties
      .map((variety) => ({
        ...variety,
        sowings: variety.sowings.filter((sowing) =>
          isDateBetween(
            sowing.date,
            monthsBehind,
            monthsAhead,
            startDate,
            endDate
          )
        ),
      }))
      .filter((variety) => !!variety.sowings.length),
  }))
}

const filterByPrograms = (allFieldSowingsPlanning, programs) => {
  return allFieldSowingsPlanning
    .map((obj) => ({
      ...obj,
      varieties: obj.varieties
        .map((variety) => ({
          ...variety,
          sowings: variety.sowings
            .map((sowing) => ({
              ...sowing,
              programs: sowing.programs.filter((program) =>
                programs.includes(
                  program.phitosanitary_category_name.toLowerCase()
                )
              ),
            }))
            .filter((sowing) => !!sowing.programs.length),
        }))
        .filter((variety) => !!variety.sowings.length),
    }))
    .filter((field) => !!field.varieties.length)
}

const filterByCrops = (allFieldSowingsPlanning, crops) => {
  return allFieldSowingsPlanning
    .map((obj) => ({
      ...obj,
      varieties: obj.varieties.filter((variety) =>
        crops.includes(String(variety.crop_id))
      ),
    }))
    .filter((field) => !!field.varieties.length)
}

const editSowing = ({
  cycle,
  programs,
  date,
  sowingId,
  statusName,
  sownArea,
  sowingDate,
  emergenceDate,
  seedClassId,
  germinativePower,
  seedVigor,
  seederSystemId,
  lineSpacing,
  populationLines,
  sownQuantity,
  operatingSpeed,
  origin,
  seedTreatment,
  inoculant,
}) => {
  sowingsPlanningSeeds.forEach((task) => {
    task.varieties.forEach((variety) => {
      variety.sowings.forEach((sowing) => {
        if (sowing.id == sowingId) {
          sowing.date = date || sowing.date
          sowing.cycle = cycle || sowing.cycle
          sowing.status_name = statusName || sowing.status_name
          sowing.programs = programs || sowing.programs
          sowing.sown_area = sownArea || sowing.sown_area
          sowing.sowing_date = sowingDate || sowing.sowing_date
          sowing.emergence_date = emergenceDate || sowing.emergence_date
          sowing.seed_class_id = seedClassId || sowing.seed_class_id
          sowing.germinative_power =
            germinativePower || sowing.germinative_power
          sowing.seed_vigor = seedVigor || sowing.seed_vigor
          sowing.seeder_system_id = seederSystemId || sowing.seeder_system_id
          sowing.line_spacing = lineSpacing || sowing.line_spacing
          sowing.population_lines = populationLines || sowing.population_lines
          sowing.sown_quantity = sownQuantity || sowing.sown_quantity
          sowing.operating_speed = operatingSpeed || sowing.operating_speed
          sowing.origin = origin || sowing.origin
          sowing.seed_treatment = seedTreatment || sowing.seed_treatment
          sowing.inoculant = inoculant || sowing.inoculant
        }
      })
    })
  })
}

const insertNewSowingToList = ({
  cropId,
  cycle,
  fieldId,
  program = null,
  varietyId,
  varName,
  sowingDate,
}) => {
  const sowingQtt =
    getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated()
      .length
  insertNewSowing(
    sowingQtt,
    cropId,
    cycle,
    fieldId,
    varietyId,
    varName,
    sowingDate
  )
  sowingsPlanningSeeds.forEach((task) => {
    if (task.field_id == fieldId) {
      let varietyIds = []
      const sowingObj = {
        id: sowingQtt + 1,
        date: sowingDate,
        status_name: 'planning',
        programs: [],
      }
      if (program && Object.keys(program).length) {
        sowingObj.programs.push(program)
      }
      task.varieties.forEach((variety) => {
        varietyIds.push(variety.variety_id)
        if (variety.crop_id == cropId && variety.variety_id == varietyId) {
          variety.sowings.push(sowingObj)
        }
      })
      if (!varietyIds.includes(Number(varietyId))) {
        const variety = varieties.find((variety) => variety.id == varietyId)
        const varietyName = varName || variety.name
        const varietyObj = {
          crop_id: Number(cropId),
          crop_message_key: crops.find((crop) => crop.id == cropId).message_key,
          field_id: Number(fieldId),
          sowings: [sowingObj],
          variety_id: Number(varietyId),
          variety_name: varietyName,
        }
        task.varieties.push(varietyObj)
      }
    }
  })
  return sowingQtt + 1
}

const insertNewSowing = (
  sowingQtt,
  cropId,
  cycle,
  fieldId,
  varietyId,
  varName,
  sowingDate
) => {
  const newSowing = {
    id: sowingQtt + 1,
    farm_id: farms[0].id,
    field_id: fieldId,
    variety_id: varietyId,
    variety_name: varName,
    crop_id: cropId,
    crop_message_key: crops.find((crop) => crop.id == cropId).message_key,
    cycle: cycle,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: sowingDate,
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: null,
    seed_class_id: null,
    germinative_power: null,
    seed_vigor: null,
    seeder_system_id: null,
    line_spacing: null,
    population_lines: null,
    sown_quantity: null,
    operating_speed: null,
    origin: null,
    seed_treatment: null,
    inoculant: null,
    harvested: null,
    harvest_quantity: null,
  }
  planningSowings.push(newSowing)
}

export { insertNewSowingToList, sowingsPlanningRoutes }
