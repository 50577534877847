export default {
  SowingView: {
    visited_page: 'visitedPage_InternalSowings',
    filtered_by_spraying: 'usedFeature_FilteredByApplication',
    edit_sowing: 'clickedButton_EditSowing',
    create_spraying: 'clickedButton_CreateApplication',
    register_spraying: 'clickedButton_RegisterApplication',
    edit_registry: 'clickedButton_EditRegistry',
    view_efficacy: 'clickedButton_ViewEfficacy',
    edit_spraying: 'clickedButton_EditSpraying',
    delete_spraying: 'clickedButton_DeleteSpraying',
    edit_growth_stage: 'clickedButton_EditPhenologicalStage',
    see_field: 'clickedButton_SeeField',
    new_product: 'clickedButton_NewProduct',
    product_options: 'clickedButton_ProductOptions',
    save_spraying: 'clickedButton_SaveApplication',
  },
}
