import { fields } from '@/modules/plot/mock/fieldSeeds.js'
import { getAllPrograms } from '@/modules/diseasesControl/mock/diseasesControlSeeds'
import { getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated } from '@/services/__mocks__/sowingsSeeds.js'

const sowingsPlanningSeeds = fields.reduce((acc, field) => {
  if (field.enabled && !field.deleted_at) {
    const varietyAddedIds = []
    const obj = {
      farm_id: field.fazenda_id,
      field_id: field.id,
      field_name: field.nome,
      varieties:
        getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated().reduce(
          (varietyAcc, varietySowing) => {
            if (
              varietySowing.field_id == field.id &&
              varietySowing.farm_id == field.fazenda_id
            ) {
              if (!varietyAddedIds.includes(varietySowing.variety_id)) {
                const varietyObj = {
                  variety_id: varietySowing.variety_id,
                  variety_name: varietySowing.variety_name,
                  crop_id: varietySowing.crop_id,
                  crop_message_key: varietySowing.crop_message_key,
                  total_sowings:
                    getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated().filter(
                      (sowing) =>
                        sowing.variety_id == varietySowing.variety_id &&
                        sowing.farm_id == field.fazenda_id
                    ).length,
                  total_sowings_programs: getAllPrograms().filter(
                    (program) => program.sowing_id == varietySowing.sowing_id
                  ).length,
                  sowings:
                    getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated().reduce(
                      (sowingAcc, sowing) => {
                        if (
                          varietySowing.variety_id == sowing.variety_id &&
                          sowing.field_id == field.id &&
                          sowing.farm_id == field.fazenda_id
                        ) {
                          const currentPrograms = getAllPrograms().reduce(
                            (programAcc, program) => {
                              if (sowing.id == program.sowing_id) {
                                programAcc.push(program)
                              }
                              return programAcc
                            },
                            []
                          )
                          const sowingObj = {
                            id: sowing.id,
                            date: sowing.estimated_date,
                            status_name: sowing.sowing_status,
                            cycle: sowing.cycle,
                            programs: currentPrograms,
                          }
                          sowingAcc.push(sowingObj)
                        }
                        return sowingAcc
                      },
                      []
                    ),
                }
                varietyAcc.push(varietyObj)
                varietyAddedIds.push(varietySowing.variety_id)
              }
            }
            return varietyAcc
          },
          []
        ),
    }
    acc.push(obj)
  }
  return acc
}, [])

const getSowingsPlanningSeeds = () => [...sowingsPlanningSeeds]

export { getSowingsPlanningSeeds, sowingsPlanningSeeds }
