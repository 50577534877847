export default {
  SowingsList: {
    header: {
      title: 'Mis siembras',
      action: 'Ejecutar siembra',
    },
    home: {
      filter_by: 'Filtrado por',
      crop: 'Cultivo',
      field: 'Lote',
      status: 'Estado',
      period: 'Período',
      clean_filters: 'Eliminar filtros',
      without_field_text: 'Crea un lote para empezar a planificar tus siembras',
      without_field_title: 'Aún no tienes un lote',
      search_for_field: 'Buscar un lote',
      search_for_crop: 'Buscar por un cultivo',
    },
    field_sowings_list: {
      see_field: 'Ver lote',
      sowings: 'siembras',
      sowing_not_found_text:
        'Haz clic en el botón de abajo y realiza una siembra, para que puedas seguir su evolución y aplicaciones',
      sowing_not_found_title: 'Realize una siembra en este lote',
      filter_not_found_text: 'Prueba a cambiar los filtros',
      filter_not_found_title: 'No encontramos resultados',
    },
    variety_list_item: {
      crop: {
        wheat: 'Trigo',
        corn: 'Maíz',
        soybean: 'Soja',
      },
      sown_at: 'Sembrado en',
      harvested_at: 'Cosechado en',
      to_sow_date: 'Semebrará en',
      estimated_harvest_date: 'Cosecha estimada',
      growth_stage: 'Etapa de crecimiento',
      productivity: 'Productividad',
      register_harvest: 'Registrar cosecha',
      sowing_actions: {
        edit: 'Editar',
        see_sowing: 'Ver siembra',
        copy_to: 'Copiar a',
        delete: 'Borrar',
      },
    },
    status_chip: {
      harvested: 'Cosecha hecha',
      ready_to_harvest: 'Listo para cosechar',
      ongoing: 'En proceso',
      scheduled: 'Programado',
    },
    delete_sowing_modal: {
      text: 'Todos los datos y programas contenidos en esta siembra se perderán y esta acción no se puede deshacer.',
      title: '¿Está seguro de que desea eliminar la siembra?',
      sowing_deleted: 'La siembra se eliminó correctamente',
    },
    sowing_infos_card: {
      sowing_date: 'Fecha de siembra',
      harvest_date: 'Fecha de cosecha',
      productivity: 'Productividad',
    },
    execute_sowing_modal: {
      edit_sowing: 'Editar siembra',
      save: 'Ahorrar',
    },
    harvest_modal: {
      register: 'Registrar',
      title: 'Registrar cosecha',
      bags_per_area: 'Sacas por hectárea',
      insert_harvested_quantity: 'Ingrese la cantidad cosechada',
      harvest_date: 'Fecha de cosecha',
      insert_date: 'Ingrese una fecha',
      did_desiccation: '¿Ha hecho desecación?',
      yes: 'Sí',
      no: 'No',
      lost_harvest: 'Perdi mi cosecha',
    },
    exceptions: {
      list_error: 'No pudimos enumerar las siembras. Inténtalo de nuevo.',
    },
  },
}
