export default {
  SowingsList: {
    header: {
      title: 'My sowings',
      action: 'Execute sowing',
    },
    home: {
      filter_by: 'Filter by',
      crop: 'Crop',
      field: 'Field',
      status: 'Status',
      period: 'Period',
      clean_filters: 'Clean filters',
      without_field_text: 'Create a field to start planning your sowings',
      without_field_title: "You still don't have a field talhão",
      search_for_field: 'Search for a field',
      search_for_crop: 'Search for a crop',
    },
    field_sowings_list: {
      see_field: 'See field',
      sowings: 'sowings',
      sowing_not_found_text:
        'Click the button below and perform a sowing, so you can follow its evolution and applications',
      sowing_not_found_title: 'Perform a sowing in this field',
      filter_not_found_text: 'Try changing the filters',
      filter_not_found_title: 'No results found',
    },
    variety_list_item: {
      crop: {
        wheat: 'Wheat',
        corn: 'Corn',
        soybean: 'Soybean',
      },
      sown_at: 'Sown at',
      harvested_at: 'Harvested at',
      to_sow_date: 'Will sow in',
      estimated_harvest_date: 'Estimated harvest',
      growth_stage: 'Growth stage',
      productivity: 'Productivity',
      register_harvest: 'Register harvest',
      sowing_actions: {
        edit: 'Edit',
        see_sowing: 'See sowing',
        copy_to: 'Copy to',
        delete: 'Delete',
      },
    },
    status_chip: {
      harvested: 'Harvest done',
      ready_to_harvest: 'Ready to harvest',
      ongoing: 'Ongoing',
      scheduled: 'Scheduled',
    },
    delete_sowing_modal: {
      text: 'All data and progrmas of this sowing will be lost and this action can not be undone.',
      title: 'Are you sure you want to delete sowing?',
      sowing_deleted: 'Sowing successfully deleted',
    },
    sowing_infos_card: {
      sowing_date: 'Sowing date',
      harvest_date: 'Harvest date',
      productivity: 'Productivity',
    },
    execute_sowing_modal: {
      edit_sowing: 'Edit sowing',
      save: 'Save',
    },
    harvest_modal: {
      register: 'Register',
      title: 'Register harvest',
      bags_per_area: 'Bags per acre',
      insert_harvested_quantity: 'Enter the quantity harvested',
      harvest_date: 'Harvest date',
      insert_date: 'Enter a date',
      did_desiccation: 'Did you desiccate?',
      yes: 'Yes',
      no: 'No',
      lost_harvest: 'Lost my harvest',
    },
    exceptions: {
      list_error: 'We were unable to list the sowings. Try again.',
    },
  },
}
