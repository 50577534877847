import { fields } from '@/modules/plot/mock/fieldSeeds.js'
import { getExecutedSowings } from '@/services/__mocks__/sowingsSeeds.js'
import moment from 'moment'

const getFieldSowingsList = () =>
  fields.reduce((acc, field) => {
    if (field.enabled) {
      const obj = {
        farm_id: field.fazenda_id,
        field_id: field.id,
        field_name: field.nome,
        sowings: getExecutedSowings().reduce((acc, sowing) => {
          if (sowing.field_id == field.id) {
            const sowingObj = {
              id: sowing.id,
              variety_id: sowing.variety_id,
              variety_name: sowing.variety_name,
              crop_id: sowing.crop_id,
              crop_message_key: sowing.crop_message_key,
              status: sowing.harvest_status,
              growth_stage: sowing.growth_stage,
              to_sow_date: sowing.estimated_date,
              sown_at: sowing.date,
              harvested_at: sowing.harvested_at,
              estimated_harvest_date: moment(sowing.estimated_date)
                .add(sowing.cycle, 'days')
                .format('YYYY-MM-DD'),
              productivity: sowing.harvested_at ? 60 : null,
            }
            acc.push(sowingObj)
          }
          return acc
        }, []),
      }
      acc.push(obj)
    }
    return acc
  }, [])

export { getFieldSowingsList }
